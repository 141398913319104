import Vue from "vue";
import Router from "vue-router";
import Dashboard from "@/views/Dashboard.vue";
import PageNotFound from "@/views/PageNotFound.vue";
import Login from "@/views/Login.vue";
import AppLayout from "@/layout/AppLayout.vue";
import store from "../store";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/perform-logout",
      name: "perform-logout",
      component: () => import("@/views/PerformLogout.vue"),
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: {
        title: "Whappy - Login",
      },
    },
    {
      path: "/linkedin",
      name: "Linkedin Authorization",
      component: () => import("@/utils/LinkedinAuthorizationHelper.vue"),
      meta: {
        title: "Whappy - Linkedin Authorization",
      },
    },
    {
      path: "/",
      component: AppLayout,
      children: [
        {
          path: "/",
          name: "benvenuto",
          component: () => import("@/views/Benvenuto.vue"),
          meta: {
            title: "Whappy - Welcome",
          },
        },
        {
          path: "/crm/gestione-landing",
          name: "Gestione Landing",
          component: () => import("@/views/LandingBuilder.vue"),
          meta: {
            title: "Whappy - Gestione Landing",
          },
        },
        {
          path: "/testtry",
          name: "TestTry",
          component: () => import("@/views/TestTry.vue"),
        },
        {
          path: "/testtry2",
          name: "TestTry2",
          component: () => import("@/views/TestTry2.vue"),
        },
        {
          path: "/LicenceManager",
          name: "LicenceManager",
          component: () => import("@/views/LicenceManager.vue"),
        },
        {
          path: "/product-licence",
          name: "FDTLicenceManager",
          component: () => import("@/views/FDTLicenceManager.vue"),
        },
        {
          path: "/kpimanagement",
          name: "KpiManagement",
          component: () => import("@/views/KpiManagement.vue"),
        },
        {
          path: "updatekpis",
          name: "UpdateKpis",
          component: () => import("@/views/UpdateKpis.vue"),
        },
        {
          path: "/changelog",
          name: "ChangeLog",
          component: () => import("@/views/ChangeLog.vue"),
        },
        {
          path: "/dashboard",
          name: "Dashboard",
          component: Dashboard,
          meta: {
            title: "Whappy - Dashboard",
          },
        },
        {
          path: "/peopleanalytics",
          name: "People analytics",
          component: () => import("@/views/PeopleAnalytics.vue"),
          meta: {
            title: "Whappy - People analysis",
          },
        },
        {
          path: "/campaigns",
          name: "Campaigns",
          component: () => import("@/views/Campaigns.vue"),
          meta: {
            title: "Whappy - Campaigns list",
          },
        },
        {
          //Non Existing campaign
          path: "/campaign",
          component: () => import("@/views/Campaign.vue"),
          children: [
            {
              path: "",
              redirect: "details",
            },
            {
              path: "details",
              name: "New Campaign",
              component: () => import("@/views/tabs/Details.vue"),
              meta: {
                title: "Whappy - New campaign",
              },
            },
          ],
        },
        {
          //Existing campaign
          path: "/campaign/:campaignId(\\d+)",
          component: () => import("@/views/Campaign.vue"),
          props: isNumber,
          children: [
            {
              path: "",
              redirect: "details",
            },
            {
              path: "details",
              name: "Campaign Details",
              component: () => import("@/views/tabs/Details.vue"),
              meta: {
                title: "Whappy - Campaign - Details",
              },
            },
            {
              path: "mission",
              name: "Campaign Mission",
              component: () => import("@/views/tabs/Mission.vue"),
              meta: {
                title: "Whappy - Campaign - Mission",
              },
            },
            {
              path: "socialshare",
              name: "Campaign Social Share",
              component: () => import("@/views/tabs/SocialShare.vue"),
              meta: {
                title: "Whappy - Campaign - Social share",
              },
            },
            {
              path: "learning",
              name: "Campaign Learning",
              component: () => import("@/views/tabs/Learning.vue"),
              meta: {
                title: "Whappy - Campaign - Learning",
              },
            },
            {
              path: "kpis",
              name: "Campaign Kpis",
              component: () => import("@/views/tabs/Kpis.vue"),
              meta: {
                title: "Whappy - Campaign - Kpis",
              },
            },
            {
              path: "task",
              name: "Campaign Task",
              component: () => import("@/views/tabs/Task.vue"),
              meta: {
                title: "Whappy - Campaign - Task",
              },
            },
            {
              path: "badges",
              name: "Campaign Badges",
              component: () => import("@/views/tabs/Badges.vue"),
              meta: {
                title: "Whappy - Campaign - Badges",
              },
            },
            {
              path: "awards",
              name: "Campaign Awards",
              component: () => import("@/views/tabs/Awards.vue"),
              meta: {
                title: "Whappy - Campaign - Awards",
              },
            },
            {
              path: "notification",
              name: "Campaign Notifications",
              component: () => import("@/views/tabs/Notification.vue"),
              meta: {
                title: "Whappy - Campaign - Notification",
              },
            },
          ],
        },
        {
          path: "/campaignResults/:campaignId(\\d+)",
          name: "Campaign Results",
          component: () => import("@/views/CampaignResults.vue"),
          meta: {
            title: "Whappy - Campaign results",
          },
        },
        {
          path: "/UpdateKpis/:campaignId(\\d+)",
          name: "Update Kpis",
          component: () => import("@/views/UpdateKpis.vue"),
          meta: {
            title: "Whappy - Update Kpis",
          },
        },
        {
          //Non Existing On Boarding
          path: "/onboarding",
          component: () => import("@/views/Onboarding.vue"),
          children: [
            {
              path: "",
              redirect: "details",
            },
            {
              path: "details",
              name: "New On Boarding",
              component: () => import("@/views/tabs/Details.vue"),
              meta: {
                title: "Whappy - New onboarding",
              },
            },
          ],
        },
        {
          //Existing On Boarding
          path: "/onboarding/:campaignId(\\d+)",
          component: () => import("@/views/Onboarding.vue"),
          props: isNumber,
          children: [
            {
              path: "",
              redirect: "details",
            },
            {
              path: "details",
              name: "On Boarding Details",
              component: () => import("@/views/tabs/Details.vue"),
              meta: {
                title: "Whappy - Onboarding - Details",
              },
            },
            {
              path: "learning",
              name: "On Boarding Learning",
              component: () => import("@/views/tabs/Learning.vue"),
              meta: {
                title: "Whappy - Onboarding - Learning",
              },
            },
            {
              path: "task",
              name: "On Boarding Task",
              component: () => import("@/views/tabs/Task.vue"),
              meta: {
                title: "Whappy - Onboarding - Task",
              },
            },
            {
              path: "badges",
              name: "On Boarding Badges",
              component: () => import("@/views/tabs/Badges.vue"),
              meta: {
                title: "Whappy - Onboarding - Badges",
              },
            },
            {
              path: "awards",
              name: "On Boarding Awards",
              component: () => import("@/views/tabs/Awards.vue"),
              meta: {
                title: "Whappy - Onboarding - Awards",
              },
            },
            {
              path: "notification",
              name: "On Boarding Notifications",
              component: () => import("@/views/tabs/Notification.vue"),
              meta: {
                title: "Whappy - Onboarding - Notification",
              },
            },
          ],
        },
        {
          //Non Existing Data Visualization campaign
          path: "/dataVisualization",
          component: () => import("@/views/DataVisualization.vue"),
          children: [
            {
              path: "",
              redirect: "details",
            },
            {
              path: "details",
              name: "New Data Visualization",
              component: () => import("@/views/tabs/Details.vue"),
              meta: {
                title: "Whappy - New Data Visualization",
              },
            },
          ],
        },
        {
          //Existing Data Visualization campaign
          path: "/dataVisualization/:campaignId(\\d+)",
          component: () => import("@/views/DataVisualization.vue"),
          props: isNumber,
          children: [
            {
              path: "",
              redirect: "details",
            },
            {
              path: "details",
              name: "Campaign Details - dv",
              component: () => import("@/views/tabs/Details.vue"),
              meta: {
                title: "Whappy - Campaign - Details",
              },
            },
            {
              path: "mission",
              name: "Campaign Mission - dv",
              component: () => import("@/views/tabs/Mission.vue"),
              meta: {
                title: "Whappy - Campaign - Mission",
              },
            },
            {
              path: "socialshare",
              name: "Campaign Social Share - dv",
              component: () => import("@/views/tabs/SocialShare.vue"),
              meta: {
                title: "Whappy - Campaign - Social share",
              },
            },
            {
              path: "learning",
              name: "Campaign Learning - dv",
              component: () => import("@/views/tabs/Learning.vue"),
              meta: {
                title: "Whappy - Campaign - Learning",
              },
            },
            {
              path: "kpis",
              name: "Campaign Kpis - dv",
              component: () => import("@/views/tabs/Kpis.vue"),
              meta: {
                title: "Whappy - Campaign - Kpis",
              },
            },
            {
              path: "task",
              name: "Campaign Task - dv",
              component: () => import("@/views/tabs/Task.vue"),
              meta: {
                title: "Whappy - Campaign - Task",
              },
            },
            {
              path: "badges",
              name: "Campaign Badges - dv",
              component: () => import("@/views/tabs/Badges.vue"),
              meta: {
                title: "Whappy - Campaign - Badges",
              },
            },
            {
              path: "awards",
              name: "Campaign Awards - dv",
              component: () => import("@/views/tabs/Awards.vue"),
              meta: {
                title: "Whappy - Campaign - Awards",
              },
            },
            {
              path: "notification - dv",
              name: "Campaign Notifications - dv",
              component: () => import("@/views/tabs/Notification.vue"),
              meta: {
                title: "Whappy - Campaign - Notification",
              },
            },
          ],
        },

        {
          path: "/companies",
          name: "Companies",
          component: () => import("@/views/Companies.vue"),
          meta: {
            title: "Whappy - Companies",
          },
        },
        {
          path: "/backoffice",
          name: "Backoffice",
          component: () => import("@/views/Backoffice.vue"),
          meta: {
            title: "Whappy - Backoffice users",
          },
        },
        {
          path: "/roles",
          name: "Roles",
          component: () => import("@/views/Roles.vue"),
          meta: {
            title: "Whappy - Users Roles",
          },
        },
        {
          path: "/backofficelevel",
          name: "BackofficeLevel",
          component: () => import("@/views/BackofficeLevel.vue"),
          meta: {
            title: "Whappy - Backoffice level",
          },
        },
        {
          path: "/users",
          name: "Users",
          component: () => import("@/views/Users.vue"),
          meta: {
            title: "Whappy - Users",
          },
        },
        {
          path: "/faqs",
          name: "Faqs",
          component: () => import("@/views/Faqs.vue"),
          meta: {
            title: "Whappy - FAQs",
          },
        },
        {
          path: "/Departments",
          name: "Departments",
          component: () => import("@/views/Departments.vue"),
          meta: {
            title: "Whappy - Departments",
          },
        },
        {
          path: "/Areas",
          name: "Areas",
          component: () => import("@/views/Areas.vue"),
          meta: {
            title: "Whappy - Areas",
          },
        },
        {
          path: "/Clusters",
          name: "Clusters",
          component: () => import("@/views/Clusters.vue"),
          meta: {
            title: "Whappy - Clusters",
          },
        },
        {
          path: "/salesChat/productManagement",
          name: "ProductManagement",
          component: () => import("@/views/SalesChat/ProductManagement.vue"),
          meta: {
            title: "Whappy - Sales Chat - Product Management",
          },
        },
        {
          path: "/salesChat/orderManagement",
          name: "OrderManagement",
          component: () => import("@/views/SalesChat/OrderManagement.vue"),
          meta: {
            title: "Whappy - Sales Chat - Order Management",
          },
        },
        {
          path: "/talentanalysis/:reportId(\\d+)",
          name: "Talent Analysis Report",
          props: isNumber,
          component: () => import("@/views/TalentAnalysisReport.vue"),
          meta: {
            title: "Whappy - Talent Analysis Report",
          },
        },
        {
          path: "/printTalentAnalysis/:reportId",
          name: "Print Talent Analysis",
          component: () => import("@/views/PrintTalentAnalysis.vue"),
          meta: {
            title: "Whappy - Print Talent Analysis Report",
          },
        },
        {
          path: "/talentanalysis",
          name: "Talent Analysis",
          component: () => import("@/views/TalentAnalysis.vue"),
          meta: {
            title: "Whappy - Talent Analysis",
          },
        },
        {
          path: "/helpcenter",
          name: "Help Center",
          component: () => import("@/views/HelpCenter.vue"),
          meta: {
            title: "Whappy - Help center",
          },
        },
        {
          path: "/status-check",
          component: () => import("@/views/StatusCheck.vue"),
        },
        {
          path: "/axerta/lista-dossier",
          component: () => import("@/views/axerta/ListaDossier.vue"),
        },
        {
          path: "/axerta/dossier/:idDossier",
          component: () => import("@/views/axerta/Dossier.vue"),
        },
        {
          path: "/axerta/ordine-di-servizio/:idOrdine",
          component: () => import("@/views/axerta/OrdineDiServizio.vue"),
        },
        {
          path: "/axerta/timeline",
          component: () => import("@/views/axerta/TimeLine.vue"),
        },
        {
          path: "/axerta/lista-rilevazioni/:idDossier",
          component: () => import("@/views/axerta/ListaRilevazioni.vue"),
        },
        {
          path: "/axerta/lista-rilevazioni-dettagliata/:idDossier",
          component: () =>
            import("@/views/axerta/ListaRilevazioniDettagliata.vue"),
        },
        {
          path: "/axerta/customers-manager",
          component: () => import("@/views/axerta/CustomersManager.vue"),
        },
        {
          path: "/yoube/censimento",
          component: () => import("@/views/yoube/Censimento.vue"),
        },
        {
          path: "/yoube/dashboard",
          component: () => import("@/views/yoube/DashboardYoube.vue"),
        },
        {
          path: "/yoube/prodotti",
          component: () => import("@/views/yoube/Prodotti.vue"),
        },
        {
          path: "/yoube/ordini",
          component: () => import("@/views/yoube/Ordini.vue"),
        },
        {
          path: "/yoube/bundles",
          component: () => import("@/views/yoube/Bundles.vue"),
        },
        {
          path: "/yoube/clienti",
          component: () => import("@/views/yoube/Clienti.vue"),
        },
        {
          path: "/yoube/offerte",
          component: () => import("@/views/yoube/Offerte.vue"),
        },
        {
          path: "/yoube/tipi-prodotto",
          component: () => import("@/views/yoube/TipiProdotto.vue"),
        },
        {
          path: "/yoube/anagrafiche-clienti",
          component: () => import("@/views/yoube/AnagraficheClienti.vue"),
        },
        {
          path: "/PageNotFound",
          name: "Page Not Found",
          component: PageNotFound,
          meta: {
            title: "Whappy - Page not found",
          },
        },
        {
          path: "/stripe/success/:idFattura",
          name: "stripeSuccess",
          component: () => import("@/views/StripeSuccess.vue"),
        },
        {
          path: "*",
          redirect: "/PageNotFound",
        },
      ],
    },
  ],
});

router.beforeEach((to, from, next) => {
  //console.log("Authenticated:" + store.getters.isAuthenticated);
  if (to.name !== "Login" && !store.getters.isAuthenticated) {
    //console.log("redirect to login");
    next({ name: "Login" });
  } else {
    //console.log("continue");
    next();
  }
});

const DEFAULT_TITLE = "Whappy";
router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

const isNumber = (route) => {
  const id = Number.parseInt(route.params.id, 10);
  if (Number.isNaN(id)) {
    return 0;
  }
  return { id };
};

export default router;
