<template>
  <div class="topbar d-flex align-items-center">
    <p class="h4 mb-0 mr-3">
      <transition name="expand">
        <b-icon
          v-if="showHamburger"
          class="hamburger-toggle"
          icon="list"
          @click="onMenuToggle"
        />
      </transition>
    </p>
    <span @click="$router.push('/')" class="cursor-pointer">
      <b-icon
        icon="house-fill"
        class=" mr-2 "
        style="font-size:1.4rem"
        @click="$router.push('/')"
      ></b-icon>
      <strong>WHAPPY</strong> Enterprise Gamification Platform</span
    >
    <div v-if="loadingDiskDataInfo" class="loading-spazio-su-disco ml-auto">
      Calcolo spazio utilizzato...
    </div>
    <div v-else class="d-flex flex-column   ml-auto" style="width:250px">
      <div
        class="d-flex justify-content-around align-items-center"
        style="font-size:12px"
      >
        <span>Quota disco</span>
        <b-progress
          :value="diskDataInfo.value"
          :max="diskDataInfo.max"
          show-progress
          class="flex-grow-1 ml-2"
          style="height: 14px;"
        >
          <b-progress-bar :value="diskDataInfo.value">
            <span
              >{{
                ((diskDataInfo.value / diskDataInfo.max) * 100).toFixed(2)
              }}
              %</span
            >
          </b-progress-bar>
        </b-progress>
      </div>
      <div class="d-flex justify-content-end" style="font-size:10px">
        <span>{{ diskDataInfo.value.toFixed(2) }} MB</span>
        <span>/ {{ (diskDataInfo.max / 1024).toFixed(0) }} GB</span>
      </div>
    </div>
    <div class="ml-auto">
      <span class="mr-2"
        >Hi,
        <strong>{{
          Object.keys(loggedUser).length != 0 ? loggedUser.nome : "User"
        }}</strong></span
      >
      <b-button
        class="text-uppercase mr-2 text-center"
        size="sm"
        style="min-width: 29px"
        ><strong>{{
          Object.keys(loggedUser).length != 0 ? loggedUser.nome.charAt(0) : "U"
        }}</strong></b-button
      >
      <b-button size="sm" variant="light" @click="logout">LogOut</b-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { AUTH_LOGOUT } from "../store/actions/auth";
import AxiosService from "@/axiosServices/AxiosService";
export default {
  props: ["showHamburger"],

  created() {
    this.getDiskDataInfo();
  },
  data() {
    return {
      diskDataInfo: {
        value: 0,
        max: 0,
      },
      loadingDiskDataInfo: false,
    };
  },
  methods: {
    getDiskDataInfo() {
      this.loadingDiskDataInfo = true;
      this.diskDataInfo = null;
      const service = new AxiosService(`System/DiskDataInfo`);
      service
        .read()
        .then((res) => {
          this.diskDataInfo = [res.mbDisponibili, res.mbUtilizzati];
          this.diskDataInfo.max = res.mbDisponibili;
          this.diskDataInfo.value = res.mbUtilizzati;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loadingDiskDataInfo = false;
        });
    },
    onMenuToggle(event) {
      this.$emit("menu-toggle", event);
    },
    logout: function() {
      this.$store.dispatch(AUTH_LOGOUT).then(() => {
        this.$router.push("/login");
      });
    },
  },
  computed: mapGetters(["loggedUser"]),
};
</script>
