<template>
  <div class="d-inline-flex align-items-center view-lang">
    <span class="text-muted">View language: </span>
    <b-form-select
      v-model="selectedLang"
      class="w-auto"
      size="sm"
      @input="$emit('input', selectedLang)"
    >
      <b-form-select-option
        v-for="option in options"
        :key="option.value"
        :value="option.value"
        >{{ option.text.toUpperCase() }}</b-form-select-option
      >
    </b-form-select>
  </div>
</template>
<script>
export default {
  props: ["value", "options"],
  data() {
    return {
      selectedLang: this.value || this.value.value,
    };
  },
};
</script>
